import React, { useState, useEffect, useMemo } from "react";
import Player from "react-howler";
import {
   Box,
   Button,
   Grid,
   Stack,
   Switch,
   Typography,
   FormControlLabel,
   FormGroup,
} from "@mui/material";

import FullControl from "./FullControl";
import WaveformPlayer from "./WaveformPlayer";

import logo from "./pdlogo.png";

export default function AudioPlayer() {
   const [autoplay, setAutoplay] = useState(false);
   const [loop, setLoop] = useState(false);

   const [loaded, setLoaded] = useState(null);
   const [files, setFiles] = useState([]);
   const [seekPosition, setSeekPosition] = useState(null);

   const handleLoopToggle = () => {
      setLoop(!loop);
   };

   const handleAutoplayToggle = () => {
      setAutoplay(!autoplay);
   };

   const onPlayerReady = (data) => {
      // console.log(data);
   };

   const timeUpdate = (data) => {
      // console.log(data);
   };

   const handleUpload = (event) => {
      // console.log(`[onChange]`, event);
      if (!event.target.files[0]) {
         return;
      }

      Object.values(event.target.files).forEach((file) => {
         let file_path = URL.createObjectURL(file);

         const { type, name } = file;

         setFiles((prevState) => {
            let newState = [...prevState];

            newState.push({
               file_path,
               type,
               name,
            });
            return newState;
         });
      });
   };

   useEffect(() => {
      // console.log(files);
   }, [files]);

   return (
      <>
         <Box sx={{ position: "fixed", bottom: 40, left: 40 }}>
            <a
               href='https://punch-drunk.com'
               target='_new'
               style={{
                  padding: 0,
                  margin: 0,
               }}
            >
               <img
                  src={logo}
                  style={{
                     width: 150,
                     display: "block",
                  }}
               />
            </a>
         </Box>

         <Grid container spacing={0} alignItems='flex-start'>
            <Grid item xs={12} lg={4}>
               <Box
                  sx={{
                     p: 4,
                     color: "#efefef",
                  }}
               >
                  {loaded && (
                     <>
                        <Typography variant='h5'>{loaded.name}</Typography>

                        {/* <FullControl
                           title={loaded.name}
                           filePath={loaded.file_path}
                           format={loaded.name.split(".").slice(-1)[0]}
                           autoplay={autoplay}
                           seekPosition={seekPosition}
                        /> */}

                        <WaveformPlayer
                           audioUrl={loaded.file_path}
                           timestamp={loaded.timestamp}
                           autoplay={autoplay}
                           loop={loop}
                        />
                     </>
                  )}

                  {!loaded && <>No Audio File Loaded.</>}
               </Box>
            </Grid>

            <Grid
               item
               xs={12}
               lg={8}
               sx={{
                  backgroundColor: "rgba(255,255,255,.1)",
                  minHeight: "100vh",
                  color: "#efefef",
               }}
            >
               <Box
                  sx={{
                     p: 4,
                  }}
               >
                  <Stack
                     direction='row'
                     alignItems='center'
                     justifyContent='space-between'
                     sx={{ mb: 2 }}
                  >
                     <Typography variant='h5'>Audio Files</Typography>

                     <FormGroup>
                        <FormControlLabel
                           control={
                              <Switch
                                 checked={loop}
                                 onChange={handleLoopToggle}
                                 inputProps={{ "aria-label": "Loop" }}
                              />
                           }
                           labelPlacement='start'
                           label='Loop'
                        />
                        <FormControlLabel
                           control={
                              <Switch
                                 checked={autoplay}
                                 onChange={handleAutoplayToggle}
                                 inputProps={{ "aria-label": "Autoplay" }}
                              />
                           }
                           labelPlacement='start'
                           label='Autoplay on Load'
                        />
                     </FormGroup>
                  </Stack>

                  <Stack
                     direction='row'
                     spacing={2}
                     alignItems='center'
                     sx={{
                        backgroundColor: "#ccc",
                        p: 2,
                        mb: 2,
                        borderRadius: 2,
                        color: "#333",
                     }}
                  >
                     <Typography variant='b'>Upload Audio</Typography>

                     <input
                        type='file'
                        className='file-input'
                        id='audio_file'
                        name='audio_file'
                        accept='audio/*'
                        multiple
                        onChange={handleUpload}
                     />
                  </Stack>

                  <Box
                     sx={{
                        borderRadius: 2,
                        overflowY: "hidden",
                     }}
                  >
                     {files &&
                        files.map((file) => (
                           <Stack
                              spacing={2}
                              justifyContent='space-between'
                              alignItems='center'
                              direction='row'
                              sx={{
                                 backgroundColor: "#333",
                                 color: "#fff",
                                 px: 4,
                                 py: 1,
                                 borderTop: "1px solid rgba(255,255,255,.1)",
                              }}
                              key={file}
                           >
                              <Box>{file.name}</Box>

                              <Box>
                                 <Button
                                    onClick={() =>
                                       setLoaded({
                                          ...file,
                                          timestamp: Date.now(),
                                       })
                                    }
                                    variant='contained'
                                    color='error'
                                 >
                                    Load
                                 </Button>
                              </Box>
                           </Stack>
                        ))}
                  </Box>
               </Box>
            </Grid>
         </Grid>
      </>
   );
}
