import React from "react";
import ReactHowler from "react-howler";

import {
   Box,
   Button,
   Grid,
   FormGroup,
   FormControlLabel,
   Slider,
   Stack,
   Switch,
   Typography,
} from "@mui/material";

const formatDuration = (totalSeconds) => {
   const hours = parseInt(Math.floor(totalSeconds / 3600))
      .toString()
      .padStart(2, "0");
   const minutes = parseInt(Math.floor((totalSeconds % 3600) / 60))
      .toString()
      .padStart(2, "0");
   const seconds = parseInt(totalSeconds - hours * 3600 - minutes * 60)
      .toString()
      .padStart(2, "0");

   return [hours, minutes, seconds].filter((item) => item !== "0").join(":");
};

class FullControl extends React.Component {
   constructor(props) {
      super(props);

      this.state = {
         playing: false,
         loaded: false,
         loop: false,
         mute: false,
         volume: 1.0,
         seek: 0.0,
         rate: 1,
         isSeeking: false,
      };
      this.handleToggle = this.handleToggle.bind(this);
      this.handleOnLoad = this.handleOnLoad.bind(this);
      this.handleOnEnd = this.handleOnEnd.bind(this);
      this.handleOnPlay = this.handleOnPlay.bind(this);
      this.handleStop = this.handleStop.bind(this);
      this.renderSeekPos = this.renderSeekPos.bind(this);
      this.handleLoopToggle = this.handleLoopToggle.bind(this);
      this.handleMuteToggle = this.handleMuteToggle.bind(this);
      this.handleMouseDownSeek = this.handleMouseDownSeek.bind(this);
      this.handleMouseUpSeek = this.handleMouseUpSeek.bind(this);
      this.handleSeekingChange = this.handleSeekingChange.bind(this);
      this.handleRate = this.handleRate.bind(this);
   }

   componentDidUpdate(prevProps) {
      // console.log(this.props);
      if (prevProps.seekPosition !== this.props.seekPosition) {
         this.setState({
            isSeeking: false,
         });

         this.player.seek(this.props.seekPosition);
      }
   }

   componentWillUnmount() {}

   handleToggle() {
      this.setState({
         playing: !this.state.playing,
      });
   }

   handleOnLoad() {
      let loadSettings = {
         loaded: true,
         duration: this.player.duration(),
      };

      // console.log(this.props.autoplay);
      if (this.props.autoplay) {
         loadSettings.playing = true;
      }

      this.setState(loadSettings);
   }

   handleOnPlay() {
      this.setState({
         playing: true,
      });
      this.renderSeekPos();
   }

   handleOnEnd() {
      this.setState({
         playing: false,
      });
   }

   handleStop() {
      this.player.stop();
      this.setState({
         playing: false, // Need to update our local state so we don't immediately invoke autoplay
      });
      this.renderSeekPos();
   }

   handleLoopToggle() {
      this.setState({
         loop: !this.state.loop,
      });
   }

   handleMuteToggle() {
      this.setState({
         mute: !this.state.mute,
      });
   }

   handleMouseDownSeek() {
      this.setState({
         isSeeking: true,
      });
   }

   handleMouseUpSeek(e) {
      this.setState({
         isSeeking: false,
      });

      this.player.seek(e.target.value);
   }

   handleSeekingChange(e) {
      this.setState({
         seek: parseFloat(e.target.value),
      });
   }

   renderSeekPos() {
      if (!this.state.isSeeking) {
         this.setState({
            seek: this.player.seek(),
         });
      }
      if (this.state.playing) {
         // this._raf = raf(this.renderSeekPos);
      }
   }

   handleRate(e) {
      const rate = parseFloat(e.target.value);
      this.player.rate(rate);
      this.setState({ rate });
   }

   render() {
      return (
         <>
            <ReactHowler
               src={[this.props.filePath]}
               format={[this.props.format]}
               playing={this.state.playing}
               onLoad={this.handleOnLoad}
               onPlay={this.handleOnPlay}
               onEnd={this.handleOnEnd}
               loop={this.state.loop}
               mute={this.state.mute}
               volume={this.state.volume}
               ref={(ref) => (this.player = ref)}
            />

            <Grid container spacing={2} alignItems='center'>
               <Grid item xs={12}>
                  <Typography variant='h5'>
                     {this.state.loaded ? this.props.title : "Loading"}
                  </Typography>
               </Grid>

               <Grid item xs={6} sm={4}>
                  <FormGroup>
                     <FormControlLabel
                        control={
                           <Switch
                              checked={this.state.loop}
                              onChange={this.handleLoopToggle}
                              inputProps={{ "aria-label": "Loop Toggle" }}
                           />
                        }
                        label='Loop'
                     />
                  </FormGroup>
               </Grid>

               <Grid item xs={6} sm={8}>
                  <FormGroup>
                     <FormControlLabel
                        control={
                           <Switch
                              checked={this.state.mute}
                              onChange={this.handleMuteToggle}
                              inputProps={{ "aria-label": "Mute Toggle" }}
                           />
                        }
                        label='Mute'
                     />
                  </FormGroup>
               </Grid>

               <Grid item xs={4}>
                  Current: {formatDuration(this.state.seek.toFixed(2))}
               </Grid>
               <Grid item xs={8}>
                  Duration:{" "}
                  {this.state.duration
                     ? formatDuration(this.state.duration.toFixed(2))
                     : "--"}
               </Grid>

               <Grid item xs={12} sm={2}>
                  <Typography variant='h6'>Volume</Typography>
               </Grid>
               <Grid item xs={12} sm={10}>
                  <Slider
                     value={parseInt(this.state.volume * 100)}
                     onChange={(e) =>
                        this.setState({
                           volume: parseInt(e.target.value) / 100,
                        })
                     }
                     min={0}
                     max={100}
                     step={1}
                     valueLabelDisplay='auto'
                  />
               </Grid>

               {/* <Grid item xs={12} sm={2}>
                  <Typography variant='h6'>Seek</Typography>
               </Grid>
               <Grid item xs={12} sm={10}>
                  <input
                     type='range'
                     min='0'
                     max={
                        this.state.duration ? this.state.duration.toFixed(2) : 0
                     }
                     step='.01'
                     value={this.state.seek}
                     onChange={this.handleSeekingChange}
                     onMouseDown={this.handleMouseDownSeek}
                     onMouseUp={this.handleMouseUpSeek}
                     style={{ width: "100%" }}
                  />
               </Grid> */}

               <Grid item xs={12} sm={2}>
                  <Typography variant='h6'>
                     Rate:
                     <br />
                     <i>{this.state.rate.toFixed(2)}x</i>
                  </Typography>
               </Grid>
               <Grid item xs={6}>
                  <Slider
                     value={parseInt(this.state.volume * 100)}
                     onChange={(e) =>
                        this.setState({
                           volume: parseInt(e.target.value) / 100,
                        })
                     }
                     min={0.25}
                     max={3}
                     step={0.25}
                     valueLabelDisplay='auto'
                     value={this.state.rate}
                     onChange={this.handleRate}
                  />
               </Grid>

               <Grid item xs={12}>
                  <Stack direction='row' spacing={2}>
                     <Button onClick={this.handleToggle} variant='contained'>
                        {this.state.playing ? "Pause" : "Play"}
                     </Button>

                     <Button
                        onClick={this.handleStop}
                        variant='contained'
                        color='error'
                     >
                        Stop
                     </Button>
                  </Stack>
               </Grid>
            </Grid>
         </>
      );
   }
}

export default FullControl;
